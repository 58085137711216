import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"
import Header from "../layouts/header"
import Footer from "../components/custom-components/FooterCream"
import ScrollToTop from "../components/scrollToTop"
import ActComs from "../components/custom-components/activitiesCommunities"
import FloatingBtn from "../components/custom-components/floatingBtn"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

import "../css/plugins.css"
import "../css/style.css"
import "../css/skin/skin-1.css"
import "../css/templete.min.css"
import "../css/custom-css/hanif-main.css"
import "../css/custom-css/custom-activitiesCommunities.css"
import bg from "../images/career-images/banner-act-comm.webp"
import Seo from "../components/seo"

const ActivitiesCommunitiesPage = ({ data }) => {
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : ""
  const { header, activities, communities } = data

  return (
    <div className="act-coms">
      <Seo title={intl.formatMessage({ id: "header.actcom" })} />

      <Header careerPage />
      <FloatingBtn />

      <div className="page-content bg-white hero-container">
        <div
          className="dlab-bnr-inr header-contact overlay-black-light bg-pt"
          style={{
            backgroundImage: "url(" + bg + ")",
            backgroundPosition: "center",
          }}
        >
          <div className="hero-content">
            <div className="container">
              <div className="dlab-bnr-inr-entry">
                <h1 className="text-white entry-contact">
                  {intl.formatMessage({
                    id: "header.actcom",
                  })}
                </h1>
                <div className="breadcrumb-row entry-contact">
                  <ul className="list-inline entry-contact">
                    <li>
                      <Link to={`${locale}/`}>
                        {intl.formatMessage({
                          id: "header.home",
                        })}
                      </Link>
                    </li>
                    <li>
                      {intl.formatMessage({
                        id: "header.actcom",
                      })}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full content-inner bg-white">
            <div className="backBtn container">
              <div className="icon-content">
                <h5 className="dlab-tilte">
                  <button className="back-btn" onClick={() => navigate(-1)}>
                    <span className="icon-sm">
                      <i className="ti-arrow-left"></i>
                    </span>
                    {intl.formatMessage({
                      id: "backbtn",
                    })}
                  </button>
                </h5>
              </div>
            </div>
          </div>
          <ActComs activities={activities} communities={communities} />
        </div>
      </div>

      <Footer hideContactInfo />
      <ScrollToTop className="style1 icon-up" />
    </div>
  )
}

export const query = graphql`
  {
    header: contentfulActCommsImages(title: { eq: "act-comms-header" }) {
      image {
        gatsbyImageData
      }
    }
    activities: contentfulActCommsImages(title: { eq: "activities" }) {
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    communities: contentfulActCommsImages(title: { eq: "communities" }) {
      image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`
export default ActivitiesCommunitiesPage
