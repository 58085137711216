import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React, { Component } from "react"
import { useIntl, FormattedMessage } from "gatsby-plugin-intl"

const ActivitiesCommunities = ({ activities, communities }) => {
  const intl = useIntl()
  return (
    <div>
      <div className="activities section-full">
        <div className="row m-lr0">
          <div
            className="col-xl-6 col-lg-12 p-lr0 d-flex dis-tbl latest-project-info bg-gray wow fadeInLeft"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="text-black align-self-center text-center responsiveText">
              <h2>
                {intl.formatMessage({
                  id: "actcom.activities.title",
                })}
              </h2>
              <p className="text-center">
                {intl.formatMessage({
                  id: "actcom.activities.desc",
                })}
              </p>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-12 p-lr0 wow fadeInRight"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="row m-a0 lightgallery">
              <div className="col-lg-12 col-md-12 col-sm-12 p-lr0">
                <div className="dlab-box overlay-gallery-bx1">
                  <div className="dlab-thum primary">
                    <GatsbyImage image={getImage(activities.image)} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="communities section-full">
        <div className="row m-lr0">
          <div
            className="col-xl-6 col-lg-12 p-lr0 wow fadeInRight"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="row m-a0 lightgallery">
              <div className="col-lg-12 col-md-12 col-sm-12 p-lr0">
                <div className="dlab-box overlay-gallery-bx1">
                  <div className="dlab-thum primary">
                    <GatsbyImage image={getImage(communities.image)} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-xl-6 col-lg-12 p-lr0 d-flex dis-tbl latest-project-info bg-gray wow fadeInLeft"
            data-wow-duration="2s"
            data-wow-delay="0.3s"
          >
            <div className="text-black align-self-center text-center">
              <h2>
                {intl.formatMessage({
                  id: "actcom.communities.title",
                })}
              </h2>
              <p className="text-center">
                {intl.formatMessage({
                  id: "actcom.communities.desc",
                })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActivitiesCommunities
